<template>
  <div class="infoDetail">
    <!-- 订单详情 -->
    <div class="detail_top">
      <div class="title">订单详情</div>
      <div class="top_banner">
        <p class="hosipital">
          <img class="iconImg" src="./img/yiyuan.png" alt="" />
          <span>合作医院</span>
        </p>
        <p class="hosipitalName">{{ hosipitalName }}</p>
      </div>
      <div class="top_item">
        <p class="applyItem">申请项目</p>
        <p class="itemName" v-for="(item, index) in itemList" :key="index">
          {{ index + 1 }}.{{ item }}
        </p>
        <!-- <p class="itemName">1.玻尿酸——下巴填充</p> -->
      </div>
      <div class="itemMoney">
        <van-cell
          title="项目总金额(元)"
          :value="$filters.formatMoney(itemMoney, 2)"
        />
      </div>
    </div>
    <div class="detail_bottom">
      <van-cell-group>
        <van-cell
          title="分期金额(元)"
          value-class="divMoney"
          :value="$filters.formatMoney(divMoney, 2)"
        />
        <van-cell title="分期期数" :value="diviNum" />
        <van-cell title="还款账户" :value="backAccount" />
        <van-cell title="提交时间" :value="submitTime" />
        <van-cell title="订单状态" value="订单签约已完成" />
      </van-cell-group>
    </div>
    <div class="btn" @click="ensure">确认</div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import APP from "@/utils/App";
import { routerTag } from "../../utils/index";
import API from "../../server/api";
export default {
  setup() {
    const state = reactive({
      hosipitalName: "",
      itemMoney: "",
      divMoney: "",
      diviNum: "",
      backAccount: "",
      submitTime: "",
      itemList: [],
    });
    const initData = async () => {
      const resultData = await API.intentions();
      console.log(resultData, "数据");
      if (resultData.code == 200) {
        const {
          loanAmount,
          loanNum,
          projectAmount,
          partnerName,
          projectName,
          submitTime,
          bankName,
          bankAccount,
        } = resultData.result;
        state.hosipitalName = partnerName;
        state.itemList = projectName;
        state.itemMoney = projectAmount;
        state.divMoney = loanAmount;
        state.diviNum = loanNum;
        state.submitTime = submitTime;
        state.backAccount = bankName + "(" + bankAccount.slice(-4) + ")";
      }
    };
    const ensure = () => {
      APP.Back(2);
    };
    onMounted(() => {
      initData();
      APP.DRAGBACKENABLE(false); //禁止左滑
      APP.SET_HEADER(false);
    });
    return {
      ...toRefs(state),
      ensure,
    };
  },
};
</script>
<style lang="less" src="./index.less" scoped></style>